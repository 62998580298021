<script lang="ts" setup>
import SharedFormButton from "~/components/global/shared/form/SharedFormButton.vue";

defineEmits<{
  (e: "link-clicked"): void;
}>();

import {getProductName, getProductRoute, getMainImageUrl} from "@shopware-pwa/helpers-next";
import {ClientApiError, Product} from "@shopware-pwa/types";

const {pushSuccess, pushError} = useNotificationsEx();
const {t} = useI18n();

const {formatLink} = useInternationalization();

const props = withDefaults(
  defineProps<{
    product: Product;
    showSize?: boolean;
    maxColors?: number;
    loading?: "lazy" | "eager" | undefined;
  }>(),
  {
    showSize: false,
    maxColors: 5,
    loading: "lazy",
  },
);
const {product} = toRefs(props);
const {addToWishlist, removeFromWishlist, isInWishlist} = useProductWishlist(product);
const { addToCart } = useAddToCart(product);

const toggleWishlistProduct = async () => {
  if (!isInWishlist.value) {
    try {
      await addToWishlist();
      return pushSuccess(
        t(`product.messages.addedToWishlist`, {
          p: props.product?.translated?.name,
        }),
        {
          timeout: 5000,
          link: "/wishlist",
        },
      );
    } catch (error) {
      const e = error as ClientApiError;
      const reason = e?.messages?.[0]?.detail ? `Reason: ${e?.messages?.[0]?.detail}` : "";
      return pushError(
        `${props.product?.translated?.name} konnte nicht zur Wunschliste hinzugefürgt werden.\n${reason}`,
        {
          timeout: 5000,
        },
      );
    }
  }
  removeFromWishlist();
};

const getCurrentProductColor = (product: Product) => {
  if (product?.optionIds && product.optionIds.length > 0) {
    for(const option of product?.optionIds) {
      const color = product?.customFields?.configurator.find(x => x.optionId === option);

      if(color) {
        return color?.name;
      }
    }
  }
  return null;
};

const getCurrentProductSize = (product: Product) => {
  if (product?.optionIds && product.optionIds.length > 0) {
    for(const option of product?.optionIds) {
      const size = product?.customFields?.sizes.find(x => x.optionId === option);

      if(size) {
        return size?.name;
      }
    }
  }
  return null;
};

let optionGroups: any[] = [];

if (props.product?.customFields?.configurator) {
  optionGroups = props.product.customFields.configurator.map((el: any) => {
    if (el.hexColor !== null && typeof el.hexColor === "string") {
      el.hexColor = el.hexColor.split(",");
    }
    return el;
  }).sort((a: any, b: any) => {
    if(a.name === getCurrentProductColor(props.product)) {
      return -1;
    }
    return a.name - b.name;
  });
}

const moveToCart = () => {
  addToCart();
  removeFromWishlist();
  pushSuccess(product.value.translated.name +  ' wurde in den Warenkorb verschoben.', { link: '/cart'});
};

const isNew = () => {
  return false;
};
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="@container/productcard group block focus:outline-2 focus:outline-black" data-testid="product-box">
    <div class="relative overflow-hidden" role="img">
<!--        :style="'background-color:' + getCurrentProductColor(product)?.hexColor[0]"-->
      <RouterLink
        :to="formatLink(getProductRoute(product))"
        class="aspect-2/3 block bg-maas-background-grey-light bg-gradient-to-t from-[rgba(255,255,255,0.5)] via-[rgba(255,255,255,0.7)] to-[rgba(255,255,255,0.5)]"
        @link-clicked="$emit('link-clicked')"
      >
        <SharedProxyImg
          v-if="getMainImageUrl(product)"
          :alt="getProductName({ product }) || ''"
          :loading="loading"
          :modifiers="{
            width: 470,
          }"
          :src="getMainImageUrl(product)"
          class="block scale-100 transition-all duration-500 group-hover:scale-105"
          class-img="h-full w-full"
          data-testid="product-box-img"
        />
      </RouterLink>
      <div class="absolute left-0 top-0 flex gap-2 p-2.5">
        <span
          v-if="product.calculatedPrice?.listPrice?.price"
          aria-label="Sale Artikel"
          class="bg-maas-primary @xs/productcard:h-9 @xs:w-9 @xs/productcard:text-sm flex h-7 w-7 items-center justify-center rounded-full font-sans text-sm text-white"
        >%</span
        >
        <span
          v-if="isNew()"
          aria-label="New Artikel"
          class="bg-maas-primary @xs/productcard:h-9 @xs/productcard:w-9 @xs/productcard:text-sm flex h-7 w-7 items-center justify-center rounded-full font-sans text-xs @xs/productcard:text-sm text-white"
        >NEU</span
        >
      </div>
      <button
        aria-label="Zur Wunschliste hinzufügen"
        class="z-2 hover:animate-heart-beat absolute right-0 top-0 p-4 bg-transparent"
        data-testid="product-box-toggle-wishlist-button"
        type="button"
        @click="toggleWishlistProduct"
      >
        <ClientOnly>
          <svg fill="none" height="20" viewBox="0 0 23 20" width="23" xmlns="http://www.w3.org/2000/svg">
            <path
              v-if="!isInWishlist"
              class="stroke-maas-border-primary stroke-2 p-4"
              d="M19.3658 10.5465L11.0243 18.8079L2.68276 10.5465C2.13256 10.0111 1.69917 9.36756 1.4099 8.65644C1.12062 7.94533 0.981713 7.18201 1.00193 6.41458C1.02214 5.64714 1.20104 4.8922 1.52735 4.19729C1.85367 3.50239 2.32033 2.88258 2.89795 2.37689C3.47558 1.8712 4.15165 1.49059 4.88359 1.25902C5.61554 1.02745 6.38751 0.949941 7.15088 1.03137C7.91425 1.11281 8.6525 1.35142 9.31912 1.73218C9.98575 2.11294 10.5663 2.6276 11.0243 3.24376C11.4842 2.63208 12.0654 2.12191 12.7316 1.74519C13.3978 1.36847 14.1345 1.1333 14.8958 1.05442C15.657 0.975529 16.4263 1.05461 17.1556 1.28672C17.8848 1.51883 18.5583 1.89897 19.1339 2.40334C19.7095 2.90772 20.1748 3.52547 20.5007 4.21793C20.8266 4.9104 21.006 5.66267 21.0278 6.42767C21.0495 7.19267 20.9131 7.95393 20.6272 8.6638C20.3412 9.37367 19.9118 10.0169 19.3658 10.5532"
            />
            <path
              v-if="isInWishlist"
              class="stroke-maas-border-primary stroke-2 p-4 fill-maas-icon-primary"
              d="M19.3658 10.5465L11.0243 18.8079L2.68276 10.5465C2.13256 10.0111 1.69917 9.36756 1.4099 8.65644C1.12062 7.94533 0.981713 7.18201 1.00193 6.41458C1.02214 5.64714 1.20104 4.8922 1.52735 4.19729C1.85367 3.50239 2.32033 2.88258 2.89795 2.37689C3.47558 1.8712 4.15165 1.49059 4.88359 1.25902C5.61554 1.02745 6.38751 0.949941 7.15088 1.03137C7.91425 1.11281 8.6525 1.35142 9.31912 1.73218C9.98575 2.11294 10.5663 2.6276 11.0243 3.24376C11.4842 2.63208 12.0654 2.12191 12.7316 1.74519C13.3978 1.36847 14.1345 1.1333 14.8958 1.05442C15.657 0.975529 16.4263 1.05461 17.1556 1.28672C17.8848 1.51883 18.5583 1.89897 19.1339 2.40334C19.7095 2.90772 20.1748 3.52547 20.5007 4.21793C20.8266 4.9104 21.006 5.66267 21.0278 6.42767C21.0495 7.19267 20.9131 7.95393 20.6272 8.6638C20.3412 9.37367 19.9118 10.0169 19.3658 10.5532"
            />
          </svg>
        </ClientOnly>
      </button>

      <div v-if="showSize" class="absolute flex z-1 top-0 bottom-0 opacity-0 group-hover:opacity-100 transition-all duration-300 w-full items-center justify-center bg-maas-background-default/70 backdrop-blur-sm">
        <div class="flex flex-col p-2.5 gap-2">
          <RouterLink
            class="border-1 inline-block rounded-md transition-all text-center select-none tap-highlight-color-transparent px-8 py-2 cursor-pointer border-maas-button-bright-border text-maas-typography-text-dark bg-maas-button-bright-background [@media(hover:hover)]:hover:border-maas-button-bright-hover-border [@media(hover:hover)]:hover:text-maas-button-bright-hover-text [@media(hover:hover)]:hover:bg-maas-button-bright-hover-background"
            :to="formatLink(getProductRoute(product))"
            @link-clicked="$emit('link-clicked')"
          >
            Zum Artikel
          </RouterLink>
          <SharedFormButton size="default" @click="moveToCart">In den Warenkorb</SharedFormButton>
        </div>
      </div>

      <div class="absolute bottom-0 w-full right-0 flex flex-wrap justify-end gap-2 p-2.5">
        <span v-if="showSize" class="items-center rounded px-2 py-1 text-sm @xs/productcard:text-sm bg-white text-black'">Größe: {{getCurrentProductSize(product)}}</span>
      </div>
    </div>

    <div class="flex-stretch @2xs/productcard:flex @2xs/productcard:flex-nowrap px-1 py-2">
      <div class="grow">
        <RouterLink
          :to="formatLink(getProductRoute(product))"
          data-testid="product-box-product-name-link"
          @link-clicked="$emit('link-clicked')"
        >
          <h4>{{ getProductName({ product }) }}</h4>
        </RouterLink>
        <ul v-if="optionGroups.length > 1" class="my-3 flex items-center gap-2">
          <template v-for="(color, index) in optionGroups" :key="index">
            <li v-if="color.hexColor && index < maxColors">
              <RouterLink
                :style="{ backgroundColor: color.hexColor[0] }"
                :to="formatLink({ path: '/' + color.seoUrl })"
                :title="color.name"
                aria-label="color"
                class="relative block h-3.5 w-3.5 rounded-full outline-double outline-1"
                :class="getCurrentProductColor(product) === color.name ? 'outline-offset-2 outline-maas-border-dark' : 'outline-offset-1 outline-maas-border-grey-normal'"
                data-testid="product-box-product-name-link"
                @link-clicked="$emit('link-clicked')"
              >
                <span
                  v-if="color.hexColor[1]"
                  :style="{ backgroundColor: color.hexColor[1] }"
                  style="position: absolute; left: 50%; right: 0; top: 0; bottom: 0; border-radius: 0 100px 100px 0"
                ></span>
              </RouterLink>
            </li>
          </template>
          <li
            v-if="optionGroups?.length > maxColors"
            aria-label="5 weitere Farben"
            class="text-maas-button-bright-text text-sm font-bold"
          >
            +{{ optionGroups?.length - maxColors }}
          </li>
        </ul>
      </div>
      <div
        class="@2xs/productcard:mt-0 @2xs/productcard:ml-5 @2xs/productcard:flex-col @2xs/productcard:gap-0 mt-2 flex shrink-0 gap-4"
      >
        <RouterLink
          :to="formatLink(getProductRoute(product))"
          class="flex gap-4 @2xs/productcard:block"
          data-testid="product-box-product-name-link"
          @link-clicked="$emit('link-clicked')"
        >
          <span v-if="product.calculatedCheapestPrice?.listPrice?.price" class="line-through">
            <SharedPrice :value="product.calculatedCheapestPrice?.listPrice?.price" class="justify-end" />
          </span>
          <span :class="{ 'text-red-600': product?.calculatedCheapestPrice?.listPrice }" class="font-bold justify-end order-first">
            <SharedPrice :value="product?.calculatedCheapestPrice?.unitPrice">
              <template v-if="product?.calculatedCheapestPrice.hasRange" v-slot:beforePrice>ab</template>
            </SharedPrice>
          </span>
        </RouterLink>
      </div>
    </div>
  </div>
</template>
